var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c("div", { staticClass: "shutleBack" }, [
            _c("div", { staticClass: "shutleTitle" }, [_vm._v("组织架构")]),
            _c(
              "div",
              { staticClass: "searchBox" },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入部门名称", clearable: "" },
                  on: { input: _vm.handleSearch },
                  model: {
                    value: _vm.searchKey,
                    callback: function ($$v) {
                      _vm.searchKey = $$v
                    },
                    expression: "searchKey",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "shutContent" },
              [
                _c("el-tree", {
                  ref: "tree",
                  attrs: {
                    data: _vm.componylist,
                    "node-key": "id",
                    props: _vm.defaultProps,
                    "default-expand-all": "",
                    "default-checked-keys": _vm.defaultCheckedKeys,
                  },
                  on: { "node-click": _vm.treeNodeClick },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ node }) {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                content: node.label,
                                placement: "bottom",
                              },
                            },
                            [
                              _c("span", { staticClass: "text" }, [
                                _vm._v(_vm._s(node.label)),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: {
                      "grid-head-btn": _vm.gridHeadBtn,
                      "search-columns": _vm.leftSearchColumns,
                      deviceProtocol: _vm.deviceProtocol,
                    },
                    on: {
                      "grid-head-search": _vm.leftGridHeadSearch,
                      "grid-head-empty": _vm.leftGridHeadEmpty,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "leftGridLayOut",
                    staticClass: "departTable",
                    attrs: {
                      "grid-row-btn": _vm.gridRowBtn,
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.leftTableData,
                      "table-loading": _vm.leftTableLoading,
                      "data-total": _vm.leftPage.total,
                      page: _vm.leftPage,
                    },
                    on: {
                      "page-current-change": _vm.leftOnLoad,
                      "page-size-change": _vm.leftOnLoad,
                      "page-refresh-change": _vm.leftOnLoad,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }